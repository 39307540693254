import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import Layout from '../components/Layout';
import { ApplicationContext } from '../components/Contexts/ApplicationContext';
import BlogRoll from '../components/Blog/BlogRoll';

const TagRoute = (props) => {
  return (
    <Layout>
      <TagPage props={props} />
    </Layout>
  );
};

export default TagRoute;

const TagPage = ({ props }) => {
  const context = useContext(ApplicationContext);
  const posts = props.data.allMarkdownRemark.edges;
  const tag = props.pageContext.title;
  const description = props.pageContext.description;
  const coverImage = props.pageContext.coverImage;
  const title = props.data.site.siteMetadata.title;
  const totalCount = props.data.allMarkdownRemark.totalCount;

  return (
    <>
      <Helmet title={`${tag} | ${title}`}>
        <body className={context.actions.getBodyClassNames('tag-template')} />
      </Helmet>
      <header
        className={classNames('main-header', 'tag-head', {
          'no-cover': !coverImage,
        })}
        style={{ backgroundImage: coverImage ? `url(${coverImage})` : null }}
      >
        <nav className="main-nav overlay clearfix">
          <a
            className="menu-button icon-menu"
            href="#menu"
            onClick={context.actions.toggleMenu}
          >
            <span className="word">Menu</span>
          </a>
        </nav>
        <div className="vertical">
          <div className="main-header-content inner">
            <h1 className="page-title">{tag}</h1>
            <h2 className="page-description">{description}</h2>
          </div>
        </div>
      </header>

      {/* {{!-- The main content area on the homepage --}} */}
      <main className="content" role="main">
        {/* {{!-- The tag below includes the post loop - partials/loop.hbs --}} */}
        <BlogRoll posts={posts} count={totalCount} />
      </main>
    </>
  );
};

export const tagPageQuery = graphql`
  query TagPage($title: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { elemMatch: { tag: { in: [$title] } } } }
      }
    ) {
      totalCount
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            isFeatured
            coverImage
            tags {
              tag
            }
            author
          }
        }
      }
    }
  }
`;
