import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Location } from '@reach/router';
import { CommentCount } from 'gatsby-plugin-disqus';

import Tags from './Tags';
import AuthorSnippet from '../BlogRoll/AuthorSnippet';
import { DISQUS_CONFIG } from '../../helpers/constants';
// import PreviewCompatibleImage from './PreviewCompatibleImage';

const BlogRoll = ({ posts, count }) => {
  return (
    <>
      {posts &&
        posts.map(({ node: post }) => (
          <article className="post" key={post.id}>
            <header className="post-header">
              <h2 className="post-title">
                <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
              </h2>
            </header>
            <section className="post-excerpt">
              <p>
                {post.excerpt}{' '}
                <Link className="read-more" to={post.fields.slug}>
                  &raquo;
                </Link>
              </p>
            </section>
            <footer className="post-meta">
              <AuthorSnippet name={post.frontmatter.author} />
              <Tags tags={post.frontmatter.tags} prefix=" on " />
              <time className="post-date" dateTime={post.frontmatter.date}>
                {post.frontmatter.date}
              </time>
              <Link
                className="post-date"
                to={`${post.fields.slug}#disqus_thread`}
              >
                <CommentCount
                  config={DISQUS_CONFIG({
                    id: post.id,
                    title: post.frontmatter.title,
                    pathname: `/${post.fields.slug}/`,
                  })}
                  placeholder="Comments"
                />
              </Link>
            </footer>
          </article>
        ))}
      <nav className="pagination" role="navigation">
        <span className="page-number"></span>
      </nav>
    </>
  );
};

BlogRoll.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string,
        excerpt: PropTypes.string,
        fields: PropTypes.shape({
          slug: PropTypes.string,
        }).isRequired,
        frontmatter: PropTypes.shape({
          title: PropTypes.string,
          date: PropTypes.dateTime,
          tags: PropTypes.array,
        }).isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
};

export default BlogRoll;
